import { useContext, useState, useEffect } from "react";
import { UserContext } from "../../api/user";
import { useDocumentTitle } from "../../CustomHooks/useDocumentTitle";
import { useDBWellApi } from "../../api/DBWellApi";
import { Card, Tabs, Button, Row, Col, Input, List, message } from "antd";
import { HexColorPicker } from "react-colorful";

const { Meta } = Card;
const { TabPane } = Tabs;

const defaultPalettes = [
    {
        chartTypeName: "trace_view",
        oldName: "",
        newName: "DefaultTraceView",
        entries: [
            { color: "#058728", position: 1 },
            { color: "#DA70D6", position: 2 },
            { color: "#1A1A1A", position: 3 },
            { color: "#4A90E2", position: 4 },
            { color: "#FF9900", position: 5 },
            { color: "#6BBE45", position: 6 },
            { color: "#A05DBB", position: 7 },
            { color: "#808080", position: 8 },
            { color: "#1ABC9C", position: 9 },
            { color: "#FF6F61", position: 10 },
        ],
    },
    {
        chartTypeName: "tfo",
        oldName: "",
        newName: "DefaultTfo",
        entries: [
            { color: "#edd8a6", position: 1 },
            { color: "#fc2412", position: 2 },
        ],
    },
    {
        chartTypeName: "loss",
        oldName: "",
        newName: "DefaultLoss",
        entries: [
            { color: "#058728", position: 1 },
            { color: "#DA70D6", position: 2 },
            { color: "#1A1A1A", position: 3 },
            { color: "#4A90E2", position: 4 },
            { color: "#FF9900", position: 5 },
            { color: "#6BBE45", position: 6 },
            { color: "#A05DBB", position: 7 },
            { color: "#808080", position: 8 },
            { color: "#1ABC9C", position: 9 },
            { color: "#FF6F61", position: 10 },
        ],
    },
];


const UserProfile = () => {
    const user = useContext(UserContext);
    const { getUserPalettes, upsertUserPalette } = useDBWellApi();
    const [palettes, setPalettes] = useState(defaultPalettes);
    const [selectedPalettes, setSelectedPalettes] = useState({});
    const [pickerVisible, setPickerVisible] = useState({});

    useDocumentTitle("Profile");

    useEffect(() => {
        getUserPalettes()
            .then((response) => {
                const userPalettes = response.data?.reduce((acc, chart) => {
                    acc[chart.chartTypeName] = {
                        chartTypeName: chart.chartTypeName,
                        oldName: chart.palettes[0]?.name || "Default",
                        newName: chart.palettes[0]?.name || "Default",
                        entries: chart.palettes[0]?.entries || [],
                    };
                    return acc;
                }, {}) || {};

                const mergedPalettes = defaultPalettes.map((defaultPalette) =>
                    userPalettes[defaultPalette.chartTypeName]
                        ? userPalettes[defaultPalette.chartTypeName]
                        : defaultPalette
                );

                setPalettes(mergedPalettes);

                const initialSelections = {};
                mergedPalettes.forEach((palette) => {
                    initialSelections[palette.chartTypeName] = palette;
                });
                setSelectedPalettes(initialSelections);
            })
            .catch((error) => {
                console.error("Error retrieving palettes:", error);
                setPalettes(defaultPalettes);

                const initialSelections = {};
                defaultPalettes.forEach((palette) => {
                    initialSelections[palette.chartTypeName] = palette;
                });
                setSelectedPalettes(initialSelections);
            });
    }, [user]);

    const handleSavePalette = async (chartType) => {
        const palette = selectedPalettes[chartType];
    
        // Automatically assign a new custom name
        let newName;
        if (chartType === "trace_view") {
            newName = "TraceView - Custom";
        } else if (chartType === "tfo") {
            newName = "Tfo - Custom";
        } else if (chartType === "loss") {
            newName = "Loss - Custom";
        }
    
        // Include the current `oldName` from the palette
        const paletteToSave = {
            ...palette,
            oldName: palette.newName, // Use the current `newName` as `oldName` for the backend
            newName, // Assign the new custom name
        };
    
        try {
            // Perform upsert
            await upsertUserPalette(paletteToSave);
    
            message.success(`${chartType} palette saved as custom.`);
    
            // Update the frontend state to reflect the new name
            setSelectedPalettes((prev) => ({
                ...prev,
                [chartType]: {
                    ...palette,
                    oldName: newName, // Update the `oldName` to reflect the new saved name
                    newName, // Ensure `newName` reflects the saved custom name
                },
            }));
        } catch (error) {
            console.error(`Error saving ${chartType} palette:`, error);
            message.error("Failed to save palette. Please try again later.");
        }
    };
    

    const handleUseDefault = async (chartType) => {
        const defaultPalette = defaultPalettes.find(
            (palette) => palette.chartTypeName === chartType
        );
    
        // Determine the newName based on the chartType
        let newName;
        if (chartType === "trace_view") {
            newName = "DefaultTraceView";
        } else if (chartType === "tfo") {
            newName = "DefaultTfo";
        } else if (chartType === "loss") {
            newName = "DefaultLoss";
        }
    
        // Get the oldName from the currently selected palette
        const oldName = selectedPalettes[chartType]?.newName || newName;
    
        const paletteToSave = {
            ...JSON.parse(JSON.stringify(defaultPalette)), // Deep copy of defaultPalette
            oldName, // Explicitly set the oldName
            newName, // Use the newName for the default state
        };
    
        // Avoid duplicate save if the palette is already in the default state
        if (
            oldName === newName &&
            JSON.stringify(paletteToSave.entries) ===
                JSON.stringify(selectedPalettes[chartType]?.entries)
        ) {
            message.info(`${chartType} is already set to default.`);
            return;
        }
    
        try {
            // Perform upsert
            await upsertUserPalette(paletteToSave);
            message.success(`${chartType} reset to default.`);
    
            // Update the state to reflect the reset default
            setSelectedPalettes((prev) => ({
                ...prev,
                [chartType]: paletteToSave,
            }));
        } catch (error) {
            console.error(`Error resetting ${chartType} to default:`, error);
            message.error(`Failed to reset ${chartType} to default. Please try again.`);
        }
    };
    
    

    const handleColorChange = (type, entryIndex, newColor) => {
        const updatedPalette = { ...selectedPalettes[type] };
        updatedPalette.entries[entryIndex].color = newColor;
        setSelectedPalettes((prev) => ({ ...prev, [type]: updatedPalette }));
    };

    const togglePicker = (type, index) => {
        setPickerVisible((prev) => ({
            ...prev,
            [`${type}-${index}`]: !prev[`${type}-${index}`],
        }));
    };

    return (
        <Card>
            <Meta title={user?.name} description={user?.email} />
            <Tabs defaultActiveKey="2">
                <TabPane tab="Pads" key="2">
                    <List
                        bordered
                        dataSource={user?.pads || []}
                        renderItem={(pad) => <List.Item>{pad}</List.Item>}
                    />
                </TabPane>
                <TabPane tab="Colors" key="1">
                    <Row gutter={16} align="top">
                        {["trace_view", "tfo", "loss"].map((type) => (
                            <Col span={8} key={type}>
                                <h2 style={{ textAlign: "left" }}>
                                    {type === "trace_view"
                                        ? "Trace View"
                                        : type === "tfo"
                                        ? "TFO"
                                        : "Loss View"}
                                </h2>
                                <h3>
                                    {selectedPalettes[type]?.newName || "Default"}{" "}
                                </h3>
                                {selectedPalettes[type]?.entries.map(
                                    (entry, entryIndex) => (
                                        <div
                                            key={entryIndex}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                marginBottom: "10px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    background: entry.color,
                                                    width: "40px",
                                                    height: "40px",
                                                    cursor: "pointer",
                                                    marginRight: "10px",
                                                }}
                                                onClick={() =>
                                                    togglePicker(type, entryIndex)
                                                }
                                            ></div>
                                            {pickerVisible[
                                                `${type}-${entryIndex}`
                                            ] && (
                                                <HexColorPicker
                                                    color={entry.color}
                                                    onChange={(newColor) =>
                                                        handleColorChange(
                                                            type,
                                                            entryIndex,
                                                            newColor
                                                        )
                                                    }
                                                />
                                            )}
                                            <Input
                                                value={entry.color}
                                                onChange={(e) =>
                                                    handleColorChange(
                                                        type,
                                                        entryIndex,
                                                        e.target.value
                                                    )
                                                }
                                                style={{
                                                    width: "120px",
                                                    marginLeft: "10px",
                                                }}
                                            />
                                        </div>
                                    )
                                )}
                                <Button
                                    type="primary"
                                    onClick={() => handleSavePalette(type)}
                                    style={{ marginRight: "10px" }}
                                >
                                    Save Custom Changes
                                </Button>
                                <Button
                                    onClick={() => handleUseDefault(type)}
                                    disabled={[
                                        "defaulttraceview",
                                        "defaultloss",
                                        "defaulttfo",
                                    ].includes(
                                        selectedPalettes[type]?.newName
                                            ?.trim()
                                            .toLowerCase()
                                    )}
                                >
                                    Use Default Colors
                                </Button>
                            </Col>
                        ))}
                    </Row>
                </TabPane>
            </Tabs>
        </Card>
    );    
};

export default UserProfile;

